import './App.css';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

function App() {
  ReactGA.initialize('G-YM3HM4WGT1');

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  const handleClick = (action) => {
    ReactGA.event({
      category: 'User',
      action: action,
    });
  };

  return (
    <div className="App">
      <div className="block">
        <h1>Links manager</h1>
        <h2>
          Best Figma plugin for links management so far.
          <br />
          Select layers → Find links → Edit.
        </h2>
        <div className="buttons">
          <a
            href="https://www.figma.com/community/plugin/1340332327846014838/links-manager-edit-links-and-url-in-any-text-layer"
            target="_blank"
            onClick={handleClick('Click community plugin page')}
          >
            <button className="button-84">Try plugin</button>
          </a>
          <a
            href="https://kolebaev.lemonsqueezy.com/buy/e8f407fb-bd40-4ec5-8308-bdae1caec0e2"
            target="_blank"
            onClick={handleClick('Click lemon squeezy store')}
          >
            <button className="button-84">Get license key</button>
          </a>
        </div>

        <img src="/image.png" alt="Links manager — Figma plugin" />
      </div>
    </div>
  );
}

export default App;
